/** @jsxRuntime classic */
/** @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import CartButton from './CartButton';

/** A button to buy via Snipcart. */
export default function ProductRequestSampleWithSnipcartButtonForList({
  product,
  ...rest
}) {
  return (
    <Box sx={{ display: 'inline' }}>
      <CartButton product={product} {...rest} />
    </Box>
  );
}
